import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/agent/workspace/vOps_provision-documentation_dev/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const InlineNotification = makeShortcode("InlineNotification");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Features</AnchorLink>
  <AnchorLinks small mdxType="AnchorLinks">
    <AnchorLink mdxType="AnchorLink">Server Deployments</AnchorLink>
    <AnchorLink mdxType="AnchorLink">Other</AnchorLink>
  </AnchorLinks>
    </AnchorLinks>
    <InlineNotification mdxType="InlineNotification">
  To see images/gifs/vidoes below, please connect to VPN
    </InlineNotification>
    <h2>{`Features`}</h2>
    <h3>{`Instance Details button`}</h3>
    <ul>
      <li parentName="ul">{`Instance Details button has been added to Server Deployments page. This button provides visibility to an instance. You can use Inventory Button to collect Metadata from managed instance. Data will be available 5-10 minutes after the initial deployment. `}</li>
    </ul>
    <p>{`Metadata Details:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`Choco Packages`}</inlineCode>{` (List of choco packs that are installed on a device)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Applications`}</inlineCode>{` (List of application names, publishers, versions and etc.)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Network`}</inlineCode>{` (IP address, MAC address, DNS, gateway, subnet mask, etc.)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Windows Roles and Features`}</inlineCode>{` (Name, display name, path, feature type, installed state, etc.)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Windows Updates`}</inlineCode>{` (Hotfix ID, installed by, installed date, etc.)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Running Services`}</inlineCode>{` (Name, display name, status, dependent services, service type, start type, etc.)
`}<img parentName="li" {...{
          "src": "http://artifactory.bddevops.io/ui/api/v1/download?repoKey=devops-shared-generic&path=documentation%252FPP%252Freleases%252Fv2024.07.03%252FInstanceDetails.gif&isNativeBrowsing=false",
          "alt": "Execute action log"
        }}></img></li>
    </ul>
    <h3>{`RSS updates`}</h3>
    <ul>
      <li parentName="ul">{`Bomgar web-hooks.`}
        <ul parentName="li">
          <li parentName="ul">{`Update environment end-points (QA, Stage, Prod).`}</li>
          <li parentName="ul">{`Create a pop-up message window when clicking on Remote Connection button.
`}<img parentName="li" {...{
              "src": "http://artifactory.bddevops.io/ui/api/v1/download?repoKey=devops-shared-generic&path=documentation%252FPP%252Freleases%252Fv2024.07.03%252FRSSUpdates.gif&isNativeBrowsing=false",
              "alt": "Execute action log"
            }}></img>
            <img parentName="li" {...{
              "src": "http://artifactory.bddevops.io/ui/api/v1/download?repoKey=devops-shared-generic&path=documentation%252FPP%252Freleases%252Fv2024.07.03%252Frss.gif&isNativeBrowsing=false",
              "alt": "Execute action log"
            }}></img></li>
        </ul>
      </li>
    </ul>
    <h3>{`Profile Templates tab`}</h3>
    <ul>
      <li parentName="ul">{`Profile Templates tab on Facilities page. This feature allows users to create a template for each product version and apply it across multiple workflows, such as Clean Install, Migration, and In-Place Upgrade.`}</li>
      <li parentName="ul">{`Add new template.`}</li>
      <li parentName="ul">{`Copy existing template from product version to new product version. For example copy template from ES System Release 1.7.4 to 1.8.0.`}</li>
      <li parentName="ul">{`Delete template.
`}<img parentName="li" {...{
          "src": "http://artifactory.bddevops.io/ui/api/v1/download?repoKey=devops-shared-generic&path=documentation%252FPP%252Freleases%252Fv2024.07.03%252FProfileTemplate.gif&isNativeBrowsing=false",
          "alt": "Execute action log"
        }}></img></li>
    </ul>
    <h3>{`Download logs`}</h3>
    <ul>
      <li parentName="ul">{`Download logs button enchancements include things like Executed By and Timestamp based on UTC time.
`}<img parentName="li" {...{
          "src": "http://artifactory.bddevops.io/ui/api/v1/download?repoKey=devops-shared-generic&path=documentation%252FPP%252Freleases%252Fv2024.07.03%252FDownloadLogsExecutedBy.gif&isNativeBrowsing=false",
          "alt": "Execute action log"
        }}></img></li>
    </ul>
    <h3>{`Inactive Deployment`}</h3>
    <ul>
      <li parentName="ul">{`Inactive Deployment button will allow user to delete old/unused server deployments from Server Deployments page.
`}<img parentName="li" {...{
          "src": "http://artifactory.bddevops.io/ui/api/v1/download?repoKey=devops-shared-generic&path=documentation%252FPP%252Freleases%252Fv2024.07.03%252FInactiveDeployment.gif&isNativeBrowsing=false",
          "alt": "Execute action log"
        }}></img></li>
    </ul>
    <h3>{`Other`}</h3>
    <ul>
      <li parentName="ul">{`Server Deployment Profile sorted by alphanumeric product version.`}</li>
      <li parentName="ul">{`Add AWS Account pool ID for non-admin users on Server Deployments page.`}</li>
      <li parentName="ul">{`Provision Portal Agent will register new device/instance in random AWS account pool for load balancing. `}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      